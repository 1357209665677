import React, { useEffect, useRef } from "react";
import "./HeroSection.css";
import foundryImage from "../../assets/images/home/bannerImg.png"; // Using an existing image from the project
import Container from "../../Components/General/Container";

const HeroSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  const scrollToForm = () => {
    const formElement = document.getElementById("signup-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="hero-section fadedBackground"
      style={{ marginTop: 0 }}
      ref={sectionRef}
    >
      <Container>
        <div className="row bannerContent">
          <div className="col-md-7 hero-section-content">
            <div>
              <h2 className="hero-title">
                You May Be <span className="highlight">Losing ₹1 Crore</span>
              </h2>
              <h2 className="title-description">
                Because you're not tracking data in real time!
              </h2>
            </div>
            <div>
              <p className="hero-description animate fade-in">
                Leading manufacturers rely on real-time data to cut losses,
                improve efficiency, and boost profitability. Is your foundry
                missing out?
              </p>
              <button
                className="npButton"
                style={{ width: "auto" }}
                onClick={scrollToForm}
              >
                Book Your FREE 1-on-1 Session
              </button>
            </div>
          </div>
          <div className="col-md-5 rightBannerImg">
            <img
              src={foundryImage}
              alt="AI-created image of a foundry with technology"
              className="hero-image"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
