import React from "react";
import RightHighlightedArrow from "../../../assets/images/icons/rgt_arrow_vector_bl.png";
import "./index.css";

const GenericGridDisplay = ({
  headerText,
  btnText,
  onBtnClick,
  imagesTab,
  mainImg,
  tabIndex,
  onImgClick,
  mobileScreenClass = "",
}) => {
  return (
    <div className={`metal-cloud-container`}>
      <div className="display-section">
        {imagesTab
          .slice(0, imagesTab?.length / 2)
          .map(({ text, icon, id = "" }, idx) => {
            return (
              <div
                style={{ cursor: onImgClick && "pointer" }}
                onClick={() => {
                  onImgClick && onImgClick(id);
                }}
                key={idx}
                className="displayBoxes"
              >
                <img src={icon} alt="" loading="lazy" />
                <p>{text}</p>
              </div>
            );
          })}
      </div>
      <div
        className="mid-section"
        style={{ height: `${tabIndex == 5 ? "400px" : "auto"}` }}
      >
        <div className="mid-header">{headerText}</div>
        {btnText && (
          <div onClick={onBtnClick} className="section-btn">
            <button>{btnText}</button>
            <img src={RightHighlightedArrow} alt="" loading="lazy" />
          </div>
        )}
        <div className="image-section">
          <img
            src={mainImg}
            style={{ height: `${tabIndex == 5 ? "350px" : "auto"}` }}
            alt=""
            loading="lazy"
          />
        </div>
      </div>

      <div className="display-section">
        {imagesTab
          .slice(imagesTab?.length / 2)
          .map(({ text, icon, id = "" }, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  onImgClick && onImgClick(id);
                }}
                style={{ cursor: onImgClick && "pointer" }}
                className="displayBoxes"
              >
                <img src={icon} alt="" loading="lazy" />
                <p>{text}</p>
              </div>
            );
          })}
      </div>
      <div className={`mobileDisplaySection ${mobileScreenClass}`}>
        {imagesTab.map(({ text, icon, alignment = "column", id = "" }, idx) => {
          return (
            <div
              onClick={() => {
                onImgClick && onImgClick(id);
              }}
              key={idx}
              style={{
                flexDirection: alignment,
                cursor: onImgClick && "pointer",
              }}
              className="displayBoxes"
            >
              <img src={icon} alt="" loading="lazy" />
              <p>{text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GenericGridDisplay;
