import React, { useState, useEffect, useRef, forwardRef } from "react";
import "./SignupForm.css";
import Container from "../../Components/General/Container";

// Custom input component with calendar icon - defined outside the main component
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="date-input-wrapper">
    <input
      type="text"
      className="date-input"
      value={value || ""}
      onClick={onClick}
      readOnly
      placeholder="Click to select date and time"
      ref={ref}
    />
    <span className="calendar-icon" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
    </span>
  </div>
));

CustomInput.displayName = "CustomDateInput";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    preferredTime: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null);
  const sectionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    // Prevent body scrolling when date picker is open on mobile
    if (isMobile && isDatePickerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
      window.removeEventListener("resize", checkMobile);
      document.body.style.overflow = "";
    };
  }, [isMobile, isDatePickerOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = formatDateTime(date);
      setFormData({
        ...formData,
        preferredTime: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        preferredTime: "",
      });
    }
  };

  const formatDateTime = (date) => {
    if (!date) return "";

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log("Form submitted:", formData);

    // Show loading state
    setIsLoading(true);

    try {
      await fetch(
        `${process.env.REACT_APP_GSCRIPT_URL}?function=updateDataTracking`,
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      // Show success message
      setFormSubmitted(true);

      // Reset form
      setFormData({
        name: "",
        email: "",
        contactNumber: "",
        preferredTime: "",
      });
      setSelectedDate(null);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section
      className="signup-form-section fadedBackground"
      id="signup-form"
      ref={sectionRef}
    >
      <Container>
        <div className="section-header">
          <h2 className="section-title animate fade-in">
            Secure Your 1-on-1 Session Today
          </h2>
          <h3 className="section-subtitle animate fade-in">
            Fill out the form below to reserve your spot for a personalized
            strategy session.
          </h3>
        </div>

        <div className="form-wrapper animate slide-in-left">
          {formSubmitted ? (
            <div className="success-message">
              <div className="success-icon">✓</div>
              <h3>Thank You!</h3>
              <p>
                Your session has been reserved. We'll contact you shortly to
                confirm the details.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your full name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactNumber">Phone Number</label>
                <input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="submit"
                  className={`npButton ${isLoading ? "loading" : ""}`}
                  disabled={isLoading}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {isLoading ? (
                    <span className="loading-spinner"></span>
                  ) : (
                    "Reserve My Spot"
                  )}
                </button>
              </div>

              <p className="privacy-note">
                No spam. No sharing your details. 100% confidential.
              </p>
            </form>
          )}
        </div>
      </Container>
    </section>
  );
};

export default SignupForm;
