import React from "react";
import "./index.css";
import { culturalValues } from "../../../constants";

function ValueSection() {
  return (
    <>
      <div className="valuesSection trust animated-section section " id="sec43">
        <div className="scrollSection1">
          <div className="container">
            <div
              className="sechead keyHead aosAnim aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="culturalValuesHeader">
                <div className="header styledHeader">ACE IT!</div>
                <div className="header">A foundation built on Values</div>
              </div>
            </div>
            <div className="culturalValuesContainer aosAnim">
              {culturalValues.map(({ title, imgSrc, description }) => (
                <div className="cultureValue">
                  <div className="cultureValueImage">
                    <img loading="lazy" src={imgSrc} alt="" />
                  </div>
                  <h5 className="culturalTitle">{title}</h5>
                  <p className="culturalDescription">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ValueSection;
