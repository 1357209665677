import React from "react";
import HeroSection from "./HeroSection";
import WhyItMatters from "./WhyItMatters";
import WhatYouWillLearn from "./WhatYouWillLearn";
import LimitedTimeOffer from "./LimitedTimeOffer";
import SignupForm from "./SignupForm";
import CustomerCards from "../MetalcloudApplication/CustomerCards/index.js";
import AccoladesAssociations from "../../Components/Home/AccoladesAssociations/index.js";
import BonusOffer from "./BonusOffer";
import "./styles.css";

const FoundryDataTracking = () => {
  return (
    <div className="foundry-data-tracking-page">
      <div className="content-wrapper">
        <HeroSection />
        <WhyItMatters />
        <WhatYouWillLearn />
        <AccoladesAssociations />
        <LimitedTimeOffer />
        <CustomerCards />
        <SignupForm />
        <BonusOffer />
      </div>
    </div>
  );
};

export default FoundryDataTracking;
