import React, { useEffect, useRef } from "react";
import "./WhatYouWillLearn.css";
import insightsImage from "../../assets/images/home/OneOnOneDiscussion.jpg"; // Using an existing image from the project
import Container from "../../Components/General/Container";

const WhatYouWillLearn = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  const scrollToForm = () => {
    const formElement = document.getElementById("signup-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="what-you-will-learn-section fadedBackground"
      ref={sectionRef}
    >
      <Container>
        <div className="section-header">
          <h2 className="section-title animate fade-in">
            This Isn't a Sales Pitch.
          </h2>
          <h3 className="section-subtitle animate fade-in">
            It's a Game Plan for Immediate Efficiency Gains!
          </h3>
        </div>
        <div className="learn-content">
          <div className="learn-image animate slide-in-left">
            <img src={insightsImage} alt="Real-time insights dashboard" />
          </div>

          <div className="learn-points">
            <div className="learn-point animate fade-in">
              <div className="point-icon">
                <i className="ri-bar-chart-fill"></i>
              </div>
              <div className="point-content">
                <h3>Real-Time Insights for Top Foundries</h3>
                <p>
                  Track melting, raw material quality, and energy use instantly.
                  Act faster, perform better.
                </p>
              </div>
            </div>

            <div className="learn-point animate fade-in">
              <div className="point-icon">
                <i className="ri-tools-fill"></i>
              </div>
              <div className="point-content">
                <h3>Free Tools to Monitor Performance Instantly</h3>
                <p>
                  Zero-cost solutions to track melting efficiency and energy
                  consumption in real-time.
                </p>
              </div>
            </div>

            <div className="learn-point animate fade-in">
              <div className="point-icon">
                <i className="ri-flashlight-fill"></i>
              </div>
              <div className="point-content">
                <h3>Quick Fixes That Boost Efficiency</h3>
                <p>Simple tweaks to increase yield and reduce power costs</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WhatYouWillLearn;
