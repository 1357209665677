import React, { useEffect, useRef, useState } from "react";
import "./LimitedTimeOffer.css";

const LimitedTimeOffer = () => {
  const sectionRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  useEffect(() => {
    const targetDate = new Date("March 22, 2025 21:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    // Update immediately
    updateCountdown();

    // Then update every second
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  const scrollToForm = () => {
    const formElement = document.getElementById("signup-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="limited-time-offer-section" ref={sectionRef}>
      <div className="limited-time-offer-container">
        <div className="offer-content animate fade-in">
          <h2 className="offer-title">
            Limited Slots Available—Reserve Your Spot Today!
          </h2>
          <p className="offer-description">
            Our technical experts can only take on a few free strategy sessions
            each month. Don't miss your chance to discover where your foundry is
            losing money—and how to fix it fast.
          </p>
          <div className="countdown-container">
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.days}</span>
              <span className="countdown-label">Days</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.hours}</span>
              <span className="countdown-label">Hours</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.minutes}</span>
              <span className="countdown-label">Minutes</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.seconds}</span>
              <span className="countdown-label">Seconds</span>
            </div>
          </div>
          <button className="npButton pulse" onClick={scrollToForm}>
            Reserve My Spot Now
          </button>
        </div>
      </div>
    </section>
  );
};

export default LimitedTimeOffer;
