import React, { useEffect, useRef } from "react";
import "./WhyItMatters.css";
import Container from "../../Components/General/Container";

const WhyItMatters = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  const scrollToForm = () => {
    const formElement = document.getElementById("signup-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section ref={sectionRef}>
      <Container>
        <div className="section-header">
          <h2 className="section-title animate fade-in">
            Every Hour Without Real-Time Data
          </h2>
          <h3 className="section-subtitle animate fade-in">
            Costs Foundries More Than They Realize
          </h3>
        </div>

        <div className="cost-items">
          <div className="cost-item animate fade-in">
            <div className="cost-content">
              <h3>2-5% higher energy costs per melt from inefficiencies</h3>
            </div>
          </div>

          <div className="cost-item animate fade-in">
            <div className="cost-content">
              <h3>Up to 3% lower yield due to hidden material losses</h3>
            </div>
          </div>

          <div className="cost-item animate fade-in">
            <div className="cost-content">
              <h3>More defects and rework from inconsistent processes</h3>
            </div>
          </div>
        </div>

        <div className="annual-loss animate fade-in">
          <h3>
            Annual Loss Potential: <br className="break-line-tag" />
            <span className="highlight">₹1 Crore</span> (or more)
          </h3>
          <button
            className="npButton cta-button"
            style={{ width: "auto", padding: "15px 30px" }}
            onClick={scrollToForm}
          >
            Book Your FREE 1-on-1 Session
          </button>
        </div>
      </Container>
    </section>
  );
};

export default WhyItMatters;
