import React, { useState, useEffect, useRef } from "react";
import "./BonusOffer.css";
import pdfGuideImage from "../../assets/images/home/BookMockup.png"; // Using an existing image from the project
import Container from "../../Components/General/Container";
import ebookPDF from "../../assets/eBook - Electricity Savings - NowPurchase_2025.pdf"; // Import the PDF from assets

const BonusOffer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupFormData, setPopupFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
  });
  const [popupSubmitted, setPopupSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      const animatedElements = currentRef.querySelectorAll(".animate");
      animatedElements.forEach((el) => observer.observe(el));
    }

    return () => {
      if (currentRef) {
        const animatedElements = currentRef.querySelectorAll(".animate");
        animatedElements.forEach((el) => observer.unobserve(el));
      }
    };
  }, []);

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupSubmitted(false);
  };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setPopupFormData({
      ...popupFormData,
      [name]: value,
    });
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();

    if (
      !popupFormData.name ||
      !popupFormData.email ||
      !popupFormData.contactNumber
    ) {
      alert("Please fill in all required fields");
      return;
    }

    setIsLoading(true);

    try {
      // Send data to Google Script
      await fetch(
        `${process.env.REACT_APP_GSCRIPT_URL}?function=updateEbookData`,
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: popupFormData.name,
            email: popupFormData.email,
            contactNumber: popupFormData.contactNumber,
            type: "bonus_guide",
          }),
        }
      );

      // Show success message
      setPopupSubmitted(true);

      // Download the PDF file
      const link = document.createElement("a");
      link.href = ebookPDF;
      link.download = "Electricity-Savings-Guide-NowPurchase.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Close popup after 3 seconds
      setTimeout(() => {
        handlePopupClose();
        // Reset form data
        setPopupFormData({
          name: "",
          email: "",
          contactNumber: "",
        });
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="fadedBackground" ref={sectionRef}>
      <Container>
        <div className="bonus-content">
          <div className="bonus-text animate fade-in">
            <h2 className="bonus-title">
              Free Guide: 5 Quick Fixes to Improve Foundry Efficiency
            </h2>
            <p className="bonus-description">
              Confirm your session and receive a free copy of our exclusive PDF
              guide with insights from 200+ foundries.
            </p>
            <button className="npButton" onClick={handlePopupOpen}>
              Get My Free Guide
            </button>
          </div>

          <div className="bonus-image animate slide-in-right">
            <img src={pdfGuideImage} alt="Free PDF guide preview" />
          </div>
        </div>
      </Container>

      {/* Popup Form */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <button className="popup-close" onClick={handlePopupClose}>
              ×
            </button>

            {popupSubmitted ? (
              <div className="popup-success">
                <div className="success-icon">✓</div>
                <h3>Thank You!</h3>
                <p>Your free guide is downloading now.</p>
              </div>
            ) : (
              <>
                <h3 className="popup-title">Get Your Free Guide</h3>
                <p className="popup-subtitle">
                  Fill in your details to receive "5 Quick Fixes to Improve
                  Foundry Efficiency"
                </p>

                <form onSubmit={handlePopupSubmit} className="popup-form">
                  <div className="popup-form-group">
                    <label htmlFor="popup-name">Name</label>
                    <input
                      type="text"
                      id="popup-name"
                      name="name"
                      value={popupFormData.name}
                      onChange={handlePopupChange}
                      placeholder="Your name"
                      required
                      disabled={isLoading}
                    />
                  </div>

                  <div className="popup-form-group">
                    <label htmlFor="popup-email">Email</label>
                    <input
                      type="email"
                      id="popup-email"
                      name="email"
                      value={popupFormData.email}
                      onChange={handlePopupChange}
                      placeholder="Your email address"
                      required
                      disabled={isLoading}
                    />
                  </div>

                  <div className="popup-form-group">
                    <label htmlFor="popup-phone">Phone Number</label>
                    <input
                      type="tel"
                      maxLength={10}
                      id="popup-phone"
                      name="contactNumber"
                      value={popupFormData.contactNumber}
                      onChange={handlePopupChange}
                      placeholder="Your phone number"
                      required
                      disabled={isLoading}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="npButton"
                      disabled={isLoading}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {isLoading ? (
                        <div className="loader-container">
                          <div className="loader"></div>
                          <span>Processing...</span>
                        </div>
                      ) : (
                        "Download Now"
                      )}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default BonusOffer;
